<template>
  <v-container fluid class="module-item pa-0 ma-0" @mouseleave="closeContext">

    <v-hover v-slot="{hover}" class="text-center" @mouseover="refreshContext">
      <v-card class="d-flex flex-column align-center justify-center fill-height" :class="[{'elevation-8': hover}, moduleSize.padding]"
              @click.stop="showContext">

        <v-btn class="btn-setting-menu" icon @click.stop="showContext($event, true)" v-if="doesSettingMenuExists && hasSettingSubModules">
          <v-icon >mdi-cog</v-icon>
        </v-btn>

        <planete-icon :dark="$vuetify.theme.dark" :width="moduleSize.icon" :height="moduleSize.icon" :key="$uuid.v4()">{{ module.icon || "planete-online" }}</planete-icon>
        <div :class="[textSize]" class="mt-4" v-html="module.name"></div>

      </v-card>
    </v-hover>


    <sub-module-menu
        :key="cheatKey"
        :style="{...styleCtx}"
        v-show="showContext_"
        @mouseover="refreshContext"
        ref="ctxSubmodules"
        :subModules="showSettings ? subModules.settingSubModules : subModules.operatingSubModules"
        :module="module"
    ></sub-module-menu>

  </v-container>
</template>

<script>
export default {
  name: "Module",
  components: {
    SubModuleMenu: () => import("@/Components/Views/Modules/Elements/SubModuleMenu")
  },
  props: {
    module: Object,
    size: Number
  },
  computed: {
    textSize() {
      return "text-size-" + ([0,1,2].indexOf(this.size) !== -1 ? (2-this.size) : 0)
    },
    moduleSize() {
      return {
        padding: "pa-" + (8 - this.size * 2),
        icon: 64 - this.size*16
      }
    },
    subModules() {
      if (this.doesSettingMenuExists) {
        let operatingSubModules = this.module.subModules
            .filter(submodule => submodule.isSettingMenu !== undefined ? !submodule.isSettingMenu : false)
        let settingSubModules = this.module.subModules
            .filter(submodule => submodule.isSettingMenu !== undefined ? submodule.isSettingMenu : false)

        return {
          settingSubModules,
          operatingSubModules
        }
      } else {
        return {
          operatingSubModules: this.module.subModules
        }
      }
    },
    doesSettingMenuExists() {
      return this.module.subModules.filter(submodule => submodule.isSettingMenu !== undefined).length > 0
    },
    hasSettingSubModules() {
      return this.module.subModules.filter(submodule => submodule.isSettingMenu).length > 0
    },
  },
  data() {
    return {
      showSettings: false,
      showContext_: false,
      tmpShowContext_: false,
      styleCtx: "",
      cheatKey: null
    }
  },
  methods: {

    htmlDecode: function (input) {
      return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
    },
    showContext: function (e, isSetting = false) {
      this.showSettings = isSetting
      this.cheatKey = this.$uuid.v4()
      this.tmpShowContext_ = true
      this.showContext_ = true
      let position = {
        top: e.clientY,
        left: e.clientX
      }
      this.styleCtx = {
        position: "fixed",
        top: position.top+"px",
        left: (+position.left+5)+"px",
      }
      let $this = this
      setTimeout(function() {
        if (position.top + $this.$refs["ctxSubmodules"].$el.clientHeight + 20 > window.innerHeight) {
          $this.styleCtx.top = ""
          $this.styleCtx.bottom = "8px"
        }
        if (position.left + $this.$refs["ctxSubmodules"].$el.clientWidth + 20 > window.innerWidth) {
          $this.styleCtx.left = ""
          $this.styleCtx.right = "8px"
        }
      }, 0)
    },
    closeContext: function () {
      let $this = this
      this.tmpShowContext_ = false

      setTimeout(function () {
        if ($this.tmpShowContext_ == false) {
          $this.showContext_ = false

        }
      }, 500)
    },
    refreshContext: function () {
      if (this.showContext_ == true) {
        this.tmpShowContext_ = true
      }
    }
  }
}
</script>

<style>

.list-submodules {
  position: absolute;
  z-index: 100;
}

.word-no-wrap {
  word-break: keep-all;
}

.text-size-0 {
  font-size: .8em;
}
.text-size-1 {
  font-size: 1em;
}
.text-size-2 {
  font-size: 1.2em;
}

.btn-setting-menu {
  position: absolute;
  top: 8px; right: 8px;
}

</style>